import React from 'react';
import { Menu } from 'lucide-react';
import { Logo } from './ui/Logo';
import { NavItem, navItems } from './navigation/NavItems';

export function Header() {
  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <Logo />
          
          <nav className="hidden md:flex space-x-8">
            {navItems.map((item) => (
              <NavItem key={item.label} item={item} />
            ))}
          </nav>
          
          <div className="md:hidden">
            <Menu className="h-6 w-6 text-gray-600" />
          </div>
        </div>
      </div>
    </header>
  );
}