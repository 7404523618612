import React from 'react';
import { Link } from 'react-router-dom';
import { blogPosts } from './blogData';

interface BlogPost {
  id: string;
  title: string;
  excerpt: string;
  date: string;
  slug: string;
  content: string;
}

export default function Blog() {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl mb-4">
            Raised Toilet Seat Blog
          </h1>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Expert advice and comprehensive guides for choosing and using raised toilet seats safely
          </p>
        </header>
        
        <div className="mt-12 max-w-lg mx-auto grid gap-8 lg:grid-cols-2 lg:max-w-none">
          {blogPosts.map((post) => (
            <article
              key={post.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden transition-transform duration-200 hover:scale-105"
            >
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <Link to={`/blog/${post.slug}`} className="block">
                    <h2 className="text-2xl font-semibold text-gray-900 hover:text-blue-600 mb-2">
                      {post.title}
                    </h2>
                    <p className="text-base text-gray-500 mb-3">{post.excerpt}</p>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <time className="text-sm text-gray-500">
                      {new Date(post.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </time>
                  </div>
                  <div className="ml-3">
                    <Link
                      to={`/blog/${post.slug}`}
                      className="text-sm font-medium text-blue-600 hover:text-blue-500"
                    >
                      Read More →
                    </Link>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
