import React from 'react';
import { siteConfig } from '../constants/siteConfig';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">About Us</h3>
            <p className="text-gray-300 text-sm">
              We're dedicated to helping you find the perfect raised toilet seat for your needs.
              Our recommendations are based on thorough research and testing.
            </p>
            <div className="mt-4 text-sm text-gray-400">
              <p>As an affiliate, we may earn from qualifying purchases made through our recommendations.</p>
            </div>
          </div>
          
          <div>
            <h3 className="text-white text-lg font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/privacy-policy" className="text-gray-300 hover:text-white text-sm">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/medical-disclaimer" className="text-gray-300 hover:text-white text-sm">
                  Medical Disclaimer
                </Link>
              </li>
              <li>
                <Link to="/affiliate-disclosure" className="text-gray-300 hover:text-white text-sm">
                  Affiliate Disclosure
                </Link>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 pt-8 border-t border-gray-700">
          <p className="text-gray-400 text-sm text-center mt-2">Your Source for Raised Toilet Seat Solutions</p>
          <p className="text-gray-400 text-sm text-center">
            &copy; {new Date().getFullYear()} {siteConfig.name}. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}