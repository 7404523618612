import React from 'react';
import { ShoppingCart } from 'lucide-react';

interface Product {
  id: number;
  name: string;
  description: string;
  features: string[];
}

const products = [
  {
    id: 1,
    name: 'Essential Hinged Toilet Seat Riser - Elongated',
    description: 'A hinged elongated toilet seat riser that adds 3.5 inches of height. Features tool-free installation and easy cleaning access.',
    features: [
      'Hinged design for easy cleaning',
      'Tool-free installation',
      '3.5 inches of elevation',
      'Elongated shape'
    ]
  },
  {
    id: 2,
    name: 'Padded Raised Toilet Seat with Safety Frame',
    description: 'A comfortable padded raised toilet seat with built-in safety frame for additional support and stability.',
    features: [
      'Padded seat for extra comfort',
      'Built-in safety frame with handles',
      'Height adjustable legs',
      'Universal fit for most toilets'
    ]
  },
  {
    id: 3,
    name: 'Tallette Locking Raised Toilet Seat',
    description: 'A secure locking raised toilet seat that provides stability and safety with its unique locking mechanism.',
    features: [
      'Secure locking mechanism',
      'Easy to clean surface',
      'Adds 5 inches of height',
      'Fits most standard toilets'
    ]
  },
  {
    id: 4,
    name: 'Big John Standard Toilet Seat',
    description: 'A heavy-duty toilet seat designed for superior strength and stability, perfect for users needing extra support.',
    features: [
      'Supports up to 1,200 pounds',
      'Extra wide seating area',
      'Durable synthetic construction',
      'Stable mounting system'
    ]
  },
  {
    id: 5,
    name: 'Heavy Duty Bariatric Commode Shower Chair',
    description: 'A versatile bariatric commode that functions both as a shower chair and toilet safety frame, providing maximum support and comfort.',
    features: [
      'Supports up to 600 pounds',
      'Padded armrests for comfort',
      'Adjustable height legs',
      'Rust-resistant aluminum frame'
    ]
  },
  {
    id: 6,
    name: 'Big John Original Bariatric Toilet Seat',
    description: 'An extra-wide, high-capacity toilet seat specifically designed for bariatric users, offering maximum comfort and durability.',
    features: [
      'Extra-wide 19-inch seating surface',
      'Supports up to 1,200 pounds',
      'Stabilizing bumpers',
      'High-impact ABS plastic construction'
    ]
  }
];

export default function FeaturedProducts() {
  return (
    <div className="bg-white py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <header className="text-center">
          <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl mb-4">
            Featured Raised Toilet Seats
          </h1>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Our selection of high-quality raised toilet seats for enhanced comfort and safety
          </p>
        </header>

        <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {products.map((product) => (
            <div key={product.id} className="group relative bg-white p-6 rounded-lg shadow-lg border border-gray-200">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-3">{product.name}</h3>
                <p className="text-gray-600 text-sm mb-4">{product.description}</p>
                <ul className="space-y-2">
                  {product.features.map((feature, index) => (
                    <li key={index} className="text-sm text-gray-600 flex items-center">
                      <span className="h-1.5 w-1.5 bg-blue-600 rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-6">
                <a
                  href="https://shareasale.com/r.cfm?b=2278&u=1520322&m=942&urllink=www%2Ethewrightstuff%2Ecom%2Fraised%2Dtoilet%2Dseats%2Ehtml%3Fviewall%3D1&afftrack="
                  target="_blank"
                  rel="nofollow noopener"
                  className="w-full bg-blue-600 text-white px-4 py-2 rounded-md flex items-center justify-center hover:bg-blue-700 transition-colors"
                >
                  <ShoppingCart className="h-5 w-5 mr-2" />
                  View Details
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
