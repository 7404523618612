import React from 'react';
import { Shield, Heart, Activity } from 'lucide-react';

export function ValueProposition() {
  const benefits = [
    {
      icon: <Heart className="h-6 w-6 text-blue-600" />,
      title: 'Enhance Comfort & Mobility',
      description: 'Experience improved comfort and easier movement in your daily routine.',
    },
    {
      icon: <Shield className="h-6 w-6 text-blue-600" />,
      title: 'Reduce Risk of Injuries',
      description: 'Stay safe with properly designed equipment that prevents accidents.',
    },
    {
      icon: <Activity className="h-6 w-6 text-blue-600" />,
      title: 'Support Recovery',
      description: 'Perfect for post-surgery recovery and long-term elderly care needs.',
    },
  ];

  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Why Choose the Right Raised Toilet Seat?
          </h2>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {benefits.map((benefit, index) => (
              <div key={index} className="pt-6">
                <div className="flow-root bg-white rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div className="inline-flex items-center justify-center p-3 bg-blue-50 rounded-md shadow-lg">
                      {benefit.icon}
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {benefit.title}
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      {benefit.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}