import React from 'react';
import { FaRuler, FaChair, FaCalculator, FaWheelchair, FaToilet, FaCheckCircle, FaShoppingCart } from 'react-icons/fa';
import { GiKneeCap } from 'react-icons/gi';
import { TbRulerMeasure } from 'react-icons/tb';
import { BiSupport } from 'react-icons/bi';

const MeasurementGuide: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-12 bg-gray-50">
      {/* Title Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Find Your Perfect Raised Toilet Seat Height
        </h1>
        <p className="text-xl text-gray-600">
          A simple guide to measuring and choosing the right height for your needs
        </p>
      </div>

      {/* Steps Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl mx-auto">
        {/* Step 1: Current Height */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-6">
            <div className="bg-blue-100 rounded-full p-4 mr-4">
              <TbRulerMeasure className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-semibold">Step 1: Current Height</h2>
          </div>
          <div className="relative h-64 bg-blue-50 rounded-lg mb-6">
            {/* Improved toilet illustration with clearer measurement */}
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="relative w-full h-full flex items-center justify-center">
                {/* Toilet */}
                <div className="relative">
                  <FaToilet className="w-32 h-32 text-blue-400" />
                  {/* Floor line */}
                  <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-400"></div>
                </div>
                
                {/* Measurement line and arrow */}
                <div className="absolute left-3/4 h-full flex items-center justify-center pointer-events-none">
                  {/* Vertical measurement line */}
                  <div className="h-3/4 w-1 bg-blue-600 relative">
                    {/* Top arrow */}
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                      <div className="w-3 h-3 border-t-2 border-r-2 border-blue-600 transform rotate-45"></div>
                    </div>
                    {/* Bottom arrow */}
                    <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2">
                      <div className="w-3 h-3 border-b-2 border-l-2 border-blue-600 transform rotate-45"></div>
                    </div>
                    {/* Measurement text */}
                    <div className="absolute -right-8 top-1/2 transform -translate-y-1/2 bg-white px-2 py-1 rounded-md shadow-sm">
                      <span className="text-blue-600 font-semibold">15"</span>
                    </div>
                    {/* Dotted lines to toilet */}
                    <div className="absolute top-0 right-0 w-8 border-t-2 border-blue-600 border-dashed"></div>
                    <div className="absolute bottom-0 right-0 w-8 border-t-2 border-blue-600 border-dashed"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-start space-x-2">
              <div className="flex-shrink-0 mt-1">
                <div className="w-2 h-2 rounded-full bg-blue-600"></div>
              </div>
              <p className="text-gray-600">
                Measure from the floor to the top edge of your toilet bowl (not including the seat)
              </p>
            </div>
            <div className="flex items-start space-x-2">
              <div className="flex-shrink-0 mt-1">
                <div className="w-2 h-2 rounded-full bg-blue-600"></div>
              </div>
              <p className="text-gray-600">
                Standard toilets typically measure 14–16 inches in height
              </p>
            </div>
            <div className="flex items-start space-x-2">
              <div className="flex-shrink-0 mt-1">
                <div className="w-2 h-2 rounded-full bg-blue-600"></div>
              </div>
              <p className="text-gray-600">
                Use a tape measure or yardstick for accurate measurement
              </p>
            </div>
          </div>
        </div>

        {/* Step 2: Ideal Height */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-6">
            <div className="bg-blue-100 rounded-full p-4 mr-4">
              <GiKneeCap className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-semibold">Step 2: Ideal Height</h2>
          </div>
          <div className="relative h-64 bg-blue-50 rounded-lg mb-6">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="relative w-full h-full flex items-center justify-center">
                {/* Person illustration */}
                <div className="relative">
                  <div className="relative">
                    {/* Body */}
                    <div className="w-24 h-48 bg-blue-400 rounded-t-full relative">
                      {/* Head */}
                      <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16 bg-blue-400 rounded-full"></div>
                      {/* Legs */}
                      <div className="absolute bottom-0 left-0 w-full flex justify-between">
                        <div className="w-8 h-24 bg-blue-400 rounded-b-lg"></div>
                        <div className="w-8 h-24 bg-blue-400 rounded-b-lg"></div>
                      </div>
                      {/* Knee indicator */}
                      <div className="absolute bottom-20 -right-6">
                        <div className="w-4 h-4 bg-red-500 rounded-full animate-pulse"></div>
                        <div className="absolute top-1/2 left-full w-16 border-t-2 border-red-500 border-dashed"></div>
                      </div>
                    </div>
                  </div>
                  {/* Floor line */}
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-48 h-1 bg-gray-400"></div>
                </div>

                {/* Measurement line and arrow */}
                <div className="absolute right-12 h-full flex items-center justify-center pointer-events-none">
                  {/* Vertical measurement line */}
                  <div className="h-3/4 w-1 bg-blue-600 relative">
                    {/* Top arrow */}
                    <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
                      <div className="w-3 h-3 border-t-2 border-r-2 border-blue-600 transform rotate-45"></div>
                    </div>
                    {/* Bottom arrow */}
                    <div className="absolute -bottom-4 left-1/2 transform -translate-x-1/2">
                      <div className="w-3 h-3 border-b-2 border-l-2 border-blue-600 transform rotate-45"></div>
                    </div>
                    {/* Measurement text */}
                    <div className="absolute -right-8 top-1/2 transform -translate-y-1/2 bg-white px-2 py-1 rounded-md shadow-sm">
                      <span className="text-blue-600 font-semibold">20"</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-3">
            <div className="flex items-start space-x-2">
              <div className="flex-shrink-0 mt-1">
                <div className="w-2 h-2 rounded-full bg-blue-600"></div>
              </div>
              <p className="text-gray-600">
                Stand naturally with feet flat on the floor
              </p>
            </div>
            <div className="flex items-start space-x-2">
              <div className="flex-shrink-0 mt-1">
                <div className="w-2 h-2 rounded-full bg-blue-600"></div>
              </div>
              <p className="text-gray-600">
                Measure from floor to the crease behind your knee
              </p>
            </div>
            <div className="flex items-start space-x-2">
              <div className="flex-shrink-0 mt-1">
                <div className="w-2 h-2 rounded-full bg-blue-600"></div>
              </div>
              <p className="text-gray-600">
                This height represents your ideal seated position
              </p>
            </div>
            <div className="flex items-start space-x-2">
              <div className="flex-shrink-0 mt-1">
                <div className="w-2 h-2 rounded-full bg-blue-600"></div>
              </div>
              <p className="text-gray-600 text-sm italic">
                Tip: Have someone help you measure for more accuracy
              </p>
            </div>
          </div>
        </div>

        {/* Step 3: Calculate */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-6">
            <div className="bg-blue-100 rounded-full p-4 mr-4">
              <FaCalculator className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-semibold">Step 3: Calculate</h2>
          </div>
          <div className="bg-blue-50 p-6 rounded-lg mb-6">
            <div className="text-center text-xl font-semibold text-blue-800">
              <p>Knee Height (20") - Toilet Height (15")</p>
              <p className="text-2xl mt-4">= 5" Ideal Lift Height</p>
            </div>
          </div>
          <p className="text-gray-600 mb-2">
            Subtract your toilet height from your knee height measurement
          </p>
          <p className="text-sm text-gray-500">
            Round up to the nearest available raised seat height option
          </p>
        </div>

        {/* Step 4: Considerations */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-6">
            <div className="bg-blue-100 rounded-full p-4 mr-4">
              <BiSupport className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-semibold">Step 4: Consider Needs</h2>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="bg-blue-50 p-4 rounded-lg text-center">
              <GiKneeCap className="w-12 h-12 text-blue-600 mx-auto mb-2" />
              <p className="text-sm">Joint Pain</p>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg text-center">
              <BiSupport className="w-12 h-12 text-blue-600 mx-auto mb-2" />
              <p className="text-sm">Stability</p>
            </div>
          </div>
          <p className="text-gray-600 mb-2">
            Consider mobility issues and comfort needs
          </p>
          <p className="text-sm text-gray-500">
            Available in 2", 3", and 5" heights to match your needs
          </p>
        </div>

        {/* Step 5: Compatibility */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-6">
            <div className="bg-blue-100 rounded-full p-4 mr-4">
              <FaToilet className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-semibold">Step 5: Check Fit</h2>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="bg-blue-50 p-4 rounded-lg text-center">
              <FaToilet className="w-12 h-12 text-blue-600 mx-auto mb-2" />
              <p className="text-sm">Round Bowl</p>
            </div>
            <div className="bg-blue-50 p-4 rounded-lg text-center">
              <FaToilet className="w-12 h-12 text-blue-600 mx-auto mb-2 transform scale-x-125" />
              <p className="text-sm">Elongated Bowl</p>
            </div>
          </div>
          <p className="text-gray-600 mb-2">
            Ensure compatibility with your toilet shape
          </p>
          <p className="text-sm text-gray-500">
            Check product listings for detailed specifications
          </p>
        </div>

        {/* Step 6: Test */}
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <div className="flex items-center mb-6">
            <div className="bg-blue-100 rounded-full p-4 mr-4">
              <FaCheckCircle className="w-8 h-8 text-blue-600" />
            </div>
            <h2 className="text-2xl font-semibold">Step 6: Test & Adjust</h2>
          </div>
          <div className="bg-blue-50 p-6 rounded-lg mb-6 text-center">
            <FaCheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <p className="text-lg font-semibold text-blue-800">
              Confirm comfort and stability
            </p>
          </div>
          <p className="text-gray-600 mb-2">
            Test the seat by sitting and standing several times
          </p>
          <p className="text-sm text-gray-500">
            Adjust height if needed for optimal comfort
          </p>
        </div>
      </div>

      {/* Call to Action */}
      <div className="mt-16 text-center">
        <h2 className="text-2xl font-bold mb-4">Ready to Find Your Perfect Fit?</h2>
        <p className="text-gray-600 mb-8">
          Browse our selection of raised toilet seats in various heights
        </p>
        <a
          href="/featured-products"
          className="inline-flex items-center bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
        >
          <FaShoppingCart className="mr-2" />
          View Products
        </a>
      </div>
    </div>
  );
};

export default MeasurementGuide;
