import React from 'react';
import { siteConfig } from '../constants/siteConfig';

const MedicalDisclaimer: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8">Medical Disclaimer</h1>
      
      <div className="prose prose-blue max-w-none">
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>
        
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
          <p className="text-yellow-700 font-medium">
            IMPORTANT: This website is not a substitute for professional medical advice, diagnosis, or treatment.
          </p>
        </div>

        <h2 className="text-2xl font-semibold mt-8 mb-4">General Disclaimer</h2>
        <p className="mb-4">
          {siteConfig.name} provides general information about raised toilet seats and bathroom safety equipment. The content on this website is for informational purposes only. We are not licensed medical professionals, physicians, or healthcare providers.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Not Medical Advice</h2>
        <p className="mb-4">
          The information provided on this website should not be used:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>As a substitute for professional medical advice</li>
          <li>To diagnose any health problems</li>
          <li>For treatment purposes</li>
          <li>As a replacement for consulting with your healthcare provider</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Medical Emergency</h2>
        <p className="mb-4">
          If you have a medical emergency, please:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Call your doctor immediately</li>
          <li>Contact emergency services</li>
          <li>Visit the nearest emergency room</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Consult Your Healthcare Provider</h2>
        <p className="mb-4">
          Always consult with your healthcare provider before:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Using any medical equipment or assistive devices</li>
          <li>Making changes to your bathroom setup</li>
          <li>Starting any new health-related routine</li>
          <li>Making decisions about your medical care</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">No Liability</h2>
        <p className="mb-4">
          {siteConfig.name} and its contributors shall not be liable for any damages or injury resulting from your access to, or inability to access, this website, or from your reliance on any information provided on this website.
        </p>

        <p className="mt-8 text-sm text-gray-600">
          If you have any questions about this medical disclaimer, please contact us at {siteConfig.social.email}
        </p>
      </div>
    </div>
  );
}

export default MedicalDisclaimer;