import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { blogPosts } from './blogData';

interface ToiletType {
  type: string;
  dimensions: string;
  compatibility: string;
  considerations: string;
}

interface MountingType {
  name: string;
  description: string;
  pros: string[];
  cons: string[];
}

interface BlogPost {
  slug: string;
  title: string;
  date: string;
  excerpt: string;
  content: string;
  sections?: { title: string; content: string }[];
  comparison?: {
    title: string;
    features: { name: string; padded: string; nonPadded: string }[];
  };
  features?: { title: string; description: string }[];
  benefits?: { title: string; description: string }[];
  materials?: {
    name: string;
    benefits: string[];
    drawbacks: string[];
  }[];
  measurements?: {
    step: string;
    instruction: string;
    importance: string;
  }[];
  heightOptions?: {
    height: string;
    description: string;
    bestFor: string;
  }[];
  commonMistakes?: {
    mistake: string;
    consequences: string[];
  }[];
  toiletTypes?: ToiletType[];
  mountingTypes?: MountingType[];
  installationSteps?: {
    step: string;
    tasks: string[];
    importance: string;
  }[];
  maintenanceTips?: {
    title: string;
    description: string;
    frequency: string;
  }[];
  stabilityChecks?: {
    title: string;
    importance: string;
    actions: string[];
  }[];
  maintenanceSchedule?: {
    task: string;
    frequency: string;
    description: string;
    priority: string;
  }[];
  safetyTips?: {
    tip: string;
    description: string;
    importance: string;
  }[];
  toolCategories?: {
    category: string;
    description: string;
    tools: {
      name: string;
      type: string;
      uses: string[];
      tips: string;
    }[];
  }[];
  cleaningSupplies?: {
    item: string;
    purpose: string;
    recommended: string;
  }[];
  seatTypeTools?: {
    seatType: string;
    requiredTools: string[];
    specialConsiderations: string;
  }[];
}

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const post = blogPosts.find(post => post.slug === slug);

  if (!post) {
    return (
      <div className="min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-blue-600 sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Post not found
                </h1>
                <p className="mt-1 text-base text-gray-500">
                  Please check the URL and try again.
                </p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to="/blog"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Go back to blog
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <article className="max-w-4xl mx-auto px-4 py-16">
      <Link 
        to="/blog"
        className="inline-flex items-center text-blue-600 hover:text-blue-800 mb-8"
      >
        <svg className="h-5 w-5 mr-2" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
        </svg>
        Back to Blog
      </Link>
      
      <header className="mb-8">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl mb-4">
          {post.title}
        </h1>
        <time className="text-gray-600">
          {new Date(post.date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </time>
      </header>

      <div className="prose prose-lg max-w-none">
        <p className="text-xl text-gray-500 mb-8">{post.excerpt}</p>
        
        <div className="mb-8">
          {post.content}
        </div>

        {post.sections && post.sections.map((section, index) => (
          <section key={index} className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {section.title}
            </h2>
            <div className="text-gray-700">
              {section.content}
            </div>
          </section>
        ))}

        {post.comparison && (
          <div className="my-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-6">
              {post.comparison.title}
            </h2>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Feature
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Padded Seats
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Non-Padded Seats
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {post.comparison.features.map((feature, index) => (
                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {feature.name}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {feature.padded}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {feature.nonPadded}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {post.features && (
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Key Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {post.features.map((feature, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-bold mb-2">{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        {post.benefits && (
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Benefits</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {post.benefits.map((benefit, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-bold mb-2">{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        {post.materials && (
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Material Options</h2>
            {post.materials.map((material, index) => (
              <div key={index} className="mb-6 bg-gray-50 p-4 rounded-lg">
                <h3 className="text-xl font-bold mb-3">{material.name}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h4 className="font-bold mb-2">Benefits:</h4>
                    <ul className="list-disc list-inside">
                      {material.benefits.map((benefit, idx) => (
                        <li key={idx}>{benefit}</li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-bold mb-2">Drawbacks:</h4>
                    <ul className="list-disc list-inside">
                      {material.drawbacks.map((drawback, idx) => (
                        <li key={idx}>{drawback}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </section>
        )}

        {post.measurements && (
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Measurement Guide</h2>
            <div className="space-y-4">
              {post.measurements.map((measurement, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-bold mb-2">Step {index + 1}: {measurement.step}</h3>
                  <p className="mb-2"><strong>How:</strong> {measurement.instruction}</p>
                  <p><strong>Why:</strong> {measurement.importance}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        {post.heightOptions && (
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Available Height Options</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {post.heightOptions.map((option, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-bold mb-2">{option.height}</h3>
                  <p className="mb-2">{option.description}</p>
                  <p className="text-sm text-gray-600">Best for: {option.bestFor}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        {post.commonMistakes && (
          <section className="mb-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Common Mistakes to Avoid</h2>
            <div className="space-y-4">
              {post.commonMistakes.map((mistake, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-bold mb-2">{mistake.mistake}</h3>
                  <ul className="list-disc list-inside">
                    {mistake.consequences.map((consequence, idx) => (
                      <li key={idx}>{consequence}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>
        )}

        {post.toiletTypes && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Toilet Types and Specifications</h2>
            <div className="grid md:grid-cols-2 gap-4">
              {post.toiletTypes.map((type, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <h3 className="text-xl font-semibold mb-2">{type.type}</h3>
                  <ul className="space-y-2">
                    <li><strong>Dimensions:</strong> {type.dimensions}</li>
                    <li><strong>Compatibility:</strong> {type.compatibility}</li>
                    <li><strong>Considerations:</strong> {type.considerations}</li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.mountingTypes && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Mounting Types</h2>
            <div className="grid md:grid-cols-2 gap-4">
              {post.mountingTypes.map((mount, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <h3 className="text-xl font-semibold mb-2">{mount.name}</h3>
                  <p className="text-gray-600 mb-3">{mount.description}</p>
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h4 className="font-semibold text-green-600 mb-2">Pros</h4>
                      <ul className="list-disc list-inside space-y-1">
                        {mount.pros.map((pro, i) => (
                          <li key={i} className="text-sm">{pro}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className="font-semibold text-red-600 mb-2">Cons</h4>
                      <ul className="list-disc list-inside space-y-1">
                        {mount.cons.map((con, i) => (
                          <li key={i} className="text-sm">{con}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.installationSteps && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Installation Steps</h2>
            <div className="space-y-6">
              {post.installationSteps.map((step, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center mb-3">
                    <div className="bg-blue-500 text-white rounded-full w-8 h-8 flex items-center justify-center mr-3">
                      {index + 1}
                    </div>
                    <h3 className="text-xl font-semibold">{step.step}</h3>
                  </div>
                  <ul className="list-disc list-inside mb-3 space-y-2">
                    {step.tasks.map((task, i) => (
                      <li key={i} className="text-gray-700">{task}</li>
                    ))}
                  </ul>
                  <p className="text-sm text-blue-600 italic">{step.importance}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.maintenanceTips && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Maintenance Schedule</h2>
            <div className="grid md:grid-cols-2 gap-4">
              {post.maintenanceTips.map((tip, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="text-lg font-semibold">{tip.title}</h3>
                    <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">
                      {tip.frequency}
                    </span>
                  </div>
                  <p className="text-gray-600">{tip.description}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.stabilityChecks && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Stability Checks</h2>
            <div className="grid md:grid-cols-2 gap-4">
              {post.stabilityChecks.map((check, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <h3 className="text-xl font-semibold mb-2">{check.title}</h3>
                  <p className="text-blue-600 text-sm mb-3">{check.importance}</p>
                  <ul className="space-y-2">
                    {check.actions.map((action, i) => (
                      <li key={i} className="flex items-start">
                        <span className="text-green-500 mr-2">✓</span>
                        {action}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.maintenanceSchedule && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Maintenance Schedule</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white rounded-lg overflow-hidden">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Task</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Frequency</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Priority</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {post.maintenanceSchedule.map((item, index) => (
                    <tr key={index}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.task}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.frequency}</td>
                      <td className="px-6 py-4 text-sm text-gray-500">{item.description}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          item.priority === 'High' ? 'bg-red-100 text-red-800' :
                          item.priority === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                          'bg-green-100 text-green-800'
                        }`}>
                          {item.priority}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {post.safetyTips && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Safety Tips</h2>
            <div className="grid md:grid-cols-2 gap-4">
              {post.safetyTips.map((tip, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md border-l-4 border-blue-500">
                  <h3 className="text-lg font-semibold mb-2">{tip.tip}</h3>
                  <p className="text-gray-600 mb-2">{tip.description}</p>
                  <p className="text-sm text-blue-600 italic">{tip.importance}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.toolCategories && (
          <div className="my-8">
            {post.toolCategories.map((category, index) => (
              <div key={index} className="mb-8">
                <h2 className="text-2xl font-bold mb-2">{category.category}</h2>
                <p className="text-gray-600 mb-4">{category.description}</p>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {category.tools.map((tool, toolIndex) => (
                    <div key={toolIndex} className="bg-white p-4 rounded-lg shadow-md">
                      <div className="flex items-center justify-between mb-3">
                        <h3 className="text-lg font-semibold">{tool.name}</h3>
                        <span className={`text-xs px-2 py-1 rounded ${
                          tool.type === 'Essential' ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'
                        }`}>
                          {tool.type}
                        </span>
                      </div>
                      <ul className="list-disc list-inside mb-3 space-y-1">
                        {tool.uses.map((use, useIndex) => (
                          <li key={useIndex} className="text-sm text-gray-600">{use}</li>
                        ))}
                      </ul>
                      <p className="text-sm text-blue-600 italic">{tool.tips}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        {post.cleaningSupplies && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Cleaning Supplies</h2>
            <div className="grid md:grid-cols-3 gap-4">
              {post.cleaningSupplies.map((supply, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <h3 className="text-lg font-semibold mb-2">{supply.item}</h3>
                  <div className="space-y-2">
                    <p className="text-sm text-gray-600"><strong>Purpose:</strong> {supply.purpose}</p>
                    <p className="text-sm text-blue-600"><strong>Recommended:</strong> {supply.recommended}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.seatTypeTools && (
          <div className="my-8">
            <h2 className="text-2xl font-bold mb-4">Tools by Seat Type</h2>
            <div className="space-y-4">
              {post.seatTypeTools.map((seatType, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                  <h3 className="text-xl font-semibold mb-3">{seatType.seatType}</h3>
                  <div className="grid md:grid-cols-2 gap-4">
                    <div>
                      <h4 className="font-semibold text-gray-700 mb-2">Required Tools:</h4>
                      <ul className="list-disc list-inside space-y-1">
                        {seatType.requiredTools.map((tool, toolIndex) => (
                          <li key={toolIndex} className="text-gray-600">{tool}</li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h4 className="font-semibold text-gray-700 mb-2">Special Considerations:</h4>
                      <p className="text-gray-600">{seatType.specialConsiderations}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </article>
  );
}
