import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost'; 
import MedicalDisclaimer from './pages/MedicalDisclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AffiliateDisclosure from './pages/AffiliateDisclosure';
import FeaturedProducts from './pages/FeaturedProducts';
import Infographic from './pages/Infographic';
import BathroomSafetyInfographic from './pages/BathroomSafetyInfographic';
import MeasurementGuide from './pages/MeasurementGuide';

// Home page components
import { Hero } from './components/Hero';
import { ValueProposition } from './components/ValueProposition';
import { MainContent } from './components/MainContent';

function HomePage() {
  return (
    <>
      <Hero />
      <ValueProposition />
      <MainContent />
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-white">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/medical-disclaimer" element={<MedicalDisclaimer />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/affiliate-disclosure" element={<AffiliateDisclosure />} />
          <Route path="/featured-products" element={<FeaturedProducts />} />
          <Route path="/infographic" element={<Infographic />} />
          <Route path="/bathroom-safety" element={<BathroomSafetyInfographic />} />
          <Route path="/measurement-guide" element={<MeasurementGuide />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;