import React from 'react';
import { Link } from 'react-router-dom';

// Import icons from react-icons
import { FaWalking, FaWheelchair, FaHandHolding, FaClock, FaTools, FaShieldAlt, FaThumbsUp, FaHeart } from 'react-icons/fa';
import { GiKneeCap } from 'react-icons/gi';
import { RiSurgicalMaskFill } from 'react-icons/ri';

const Infographic: React.FC = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {/* Title Section */}
      <h1 className="text-4xl font-bold text-center mb-12 text-blue-800">
        Is a Raised Toilet Seat Right for You?
      </h1>

      {/* Section 1: Key Beneficiary Groups */}
      <div className="grid md:grid-cols-5 gap-6 mb-16">
        {[
          {
            icon: <FaWalking className="w-12 h-12" />,
            title: "Elderly",
            text: "Over 30% of adults 65+ experience difficulty using a standard-height toilet."
          },
          {
            icon: <GiKneeCap className="w-12 h-12" />,
            title: "Arthritis",
            text: "1 in 4 adults with arthritis report knee pain that makes sitting and standing challenging."
          },
          {
            icon: <RiSurgicalMaskFill className="w-12 h-12" />,
            title: "Post-Surgery",
            text: "Doctors often recommend raised seats post-hip or knee surgery for faster, safer recovery."
          },
          {
            icon: <FaWheelchair className="w-12 h-12" />,
            title: "Mobility Challenges",
            text: "A raised toilet seat can reduce the bend needed by up to 50%, easing strain on hips and knees."
          },
          {
            icon: <FaHandHolding className="w-12 h-12" />,
            title: "Caregivers",
            text: "A safer, more accessible toilet setup reduces caregiver strain and helps maintain patient dignity."
          }
        ].map((item, index) => (
          <div key={index} className="text-center p-4 bg-white rounded-lg shadow-lg">
            <div className="text-blue-600 flex justify-center mb-4">{item.icon}</div>
            <h3 className="font-bold mb-2">{item.title}</h3>
            <p className="text-sm text-gray-600">{item.text}</p>
          </div>
        ))}
      </div>

      {/* Section 2: How it Helps */}
      <div className="bg-gray-50 p-8 rounded-lg mb-16">
        <h2 className="text-2xl font-bold mb-6 text-center">How a Raised Toilet Seat Helps</h2>
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          <div className="flex-1 text-center">
            <p className="mb-4 font-semibold text-blue-800">Standard Toilet</p>
            <div className="relative h-48 w-full flex items-center justify-center">
              {/* Standard Toilet SVG */}
              <svg viewBox="0 0 200 200" className="h-full w-auto">
                <rect x="60" y="120" width="80" height="40" fill="#e5e7eb" stroke="#4b5563" strokeWidth="2" />
                <rect x="50" y="70" width="100" height="50" fill="#e5e7eb" stroke="#4b5563" strokeWidth="2" />
                <path d="M 70 160 L 70 180" stroke="#4b5563" strokeWidth="2" />
                <path d="M 130 160 L 130 180" stroke="#4b5563" strokeWidth="2" />
                {/* Person silhouette */}
                <path d="M 160 90 C 160 70, 140 60, 120 70 L 120 120" stroke="#3b82f6" strokeWidth="2" fill="none"/>
                <circle cx="165" cy="85" r="5" fill="#3b82f6"/>
                {/* Height indicator */}
                <line x1="180" y1="70" x2="180" y2="120" stroke="#ef4444" strokeWidth="2"/>
                <line x1="175" y1="70" x2="185" y2="70" stroke="#ef4444" strokeWidth="2"/>
                <line x1="175" y1="120" x2="185" y2="120" stroke="#ef4444" strokeWidth="2"/>
                <text x="190" y="95" fill="#ef4444" fontSize="12">15"</text>
              </svg>
            </div>
          </div>
          <div className="flex-1 text-center">
            <p className="mb-4 font-semibold text-blue-800">Raised Toilet Seat</p>
            <div className="relative h-48 w-full flex items-center justify-center">
              {/* Raised Toilet SVG */}
              <svg viewBox="0 0 200 200" className="h-full w-auto">
                <rect x="60" y="120" width="80" height="40" fill="#e5e7eb" stroke="#4b5563" strokeWidth="2" />
                <rect x="50" y="70" width="100" height="50" fill="#e5e7eb" stroke="#4b5563" strokeWidth="2" />
                <rect x="55" y="50" width="90" height="20" fill="#93c5fd" stroke="#3b82f6" strokeWidth="2" />
                <path d="M 70 160 L 70 180" stroke="#4b5563" strokeWidth="2" />
                <path d="M 130 160 L 130 180" stroke="#4b5563" strokeWidth="2" />
                {/* Person silhouette */}
                <path d="M 160 70 C 160 50, 140 40, 120 50 L 120 100" stroke="#3b82f6" strokeWidth="2" fill="none"/>
                <circle cx="165" cy="65" r="5" fill="#3b82f6"/>
                {/* Height indicator */}
                <line x1="180" y1="50" x2="180" y2="120" stroke="#ef4444" strokeWidth="2"/>
                <line x1="175" y1="50" x2="185" y2="50" stroke="#ef4444" strokeWidth="2"/>
                <line x1="175" y1="120" x2="185" y2="120" stroke="#ef4444" strokeWidth="2"/>
                <text x="190" y="85" fill="#ef4444" fontSize="12">19"</text>
              </svg>
            </div>
          </div>
        </div>
        <div className="mt-6 text-center">
          <ul className="space-y-2">
            <li>"Raised seats add 2–6 inches, reducing strain and improving balance."</li>
            <li>"Less stress on joints can decrease discomfort and reduce fall risk."</li>
            <li>"Over 70% of occupational therapists recommend raised toilet seats for clients with mobility issues."</li>
          </ul>
        </div>
      </div>

      {/* Section 3: Additional Benefits */}
      <div className="grid md:grid-cols-3 gap-6 mb-16">
        {[
          {
            icon: <FaShieldAlt className="w-8 h-8" />,
            title: "Safety",
            text: "Lower risk of slips and falls in the bathroom—one of the most hazardous areas of the home."
          },
          {
            icon: <FaThumbsUp className="w-8 h-8" />,
            title: "Independence",
            text: "Enhance personal freedom and confidence in daily routines."
          },
          {
            icon: <FaHeart className="w-8 h-8" />,
            title: "Comfort",
            text: "Easier, more natural posture reduces discomfort for prolonged use."
          }
        ].map((benefit, index) => (
          <div key={index} className="text-center p-6 bg-white rounded-lg shadow-lg">
            <div className="text-blue-600 flex justify-center mb-4">{benefit.icon}</div>
            <h3 className="font-bold mb-2">{benefit.title}</h3>
            <p className="text-gray-600">{benefit.text}</p>
          </div>
        ))}
      </div>

      {/* Section 4: Fast and Easy Integration */}
      <div className="bg-blue-50 p-8 rounded-lg mb-16">
        <h2 className="text-2xl font-bold mb-6 text-center">Fast and Easy Integration</h2>
        <div className="flex justify-center gap-12">
          <div className="text-center">
            <FaClock className="w-12 h-12 mx-auto text-blue-600 mb-4" />
            <p>"Most models install in under 10 minutes."</p>
          </div>
          <div className="text-center">
            <FaTools className="w-12 h-12 mx-auto text-blue-600 mb-4" />
            <p>"Tool-free designs make setup simple—no handyman required."</p>
          </div>
        </div>
      </div>

      {/* Footer / Call-to-Action */}
      <div className="text-center bg-gradient-to-r from-blue-800 to-blue-600 text-white p-8 rounded-lg shadow-xl">
        <h3 className="text-2xl font-bold mb-4">
          Ready to Find Your Perfect Raised Toilet Seat?
        </h3>
        <p className="text-lg mb-6">
          Compare top-rated models selected by our experts for quality, comfort, and safety.
        </p>
        <Link 
          to="/featured-products"
          className="inline-block bg-white text-blue-800 px-8 py-3 rounded-full font-semibold text-lg transition-all hover:bg-blue-100 hover:shadow-lg transform hover:-translate-y-1"
        >
          View Featured Products →
        </Link>
      </div>
    </div>
  );
};

export default Infographic;
