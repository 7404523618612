export const siteConfig = {
  name: 'ToiletSeatHelper.com',
  description: 'Expert guidance for choosing and maintaining the perfect raised toilet seat. Find comprehensive reviews, buying guides, maintenance checklists, and safety tips for elderly care and mobility assistance.',
  domain: 'toiletseathelper.com',
  social: {
    facebook: '#',
    twitter: '#',
    instagram: '#',
    email: 'info@toiletseathelper.com'
  },
  seo: {
    keywords: [
      'raised toilet seat',
      'elevated toilet seat',
      'handicap toilet seat',
      'toilet seat riser',
      'bathroom safety equipment',
      'elderly bathroom aids',
      'medical toilet seat',
      'disability toilet aids',
      'toilet seat maintenance',
      'bathroom safety tools',
      'toilet seat cleaning guide',
      'seasonal maintenance checklist',
      'bathroom maintenance tools',
      'toilet seat installation tools',
      'bathroom safety maintenance',
      'toilet seat replacement parts'
    ],
    author: 'ToiletSeatHelper.com Team'
  }
} as const;