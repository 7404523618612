import React from 'react';
import { siteConfig } from '../constants/siteConfig';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
      
      <div className="prose prose-blue max-w-none">
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">Introduction</h2>
        <p className="mb-4">
          At {siteConfig.name}, we respect your privacy and are committed to protecting your personal data. This privacy policy explains how we collect, use, and safeguard your information when you visit our website.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Information We Collect</h2>
        <p className="mb-4">We may collect:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Personal information (name, email) when voluntarily provided</li>
          <li>Usage data (browser type, device information)</li>
          <li>Cookies and tracking information</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
        <p className="mb-4">We use collected information to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Improve our website and services</li>
          <li>Send newsletters (if subscribed)</li>
          <li>Respond to inquiries</li>
          <li>Analyze usage patterns</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Third-Party Disclosure</h2>
        <p className="mb-4">
          We do not sell or trade your personal information. We may share data with trusted partners to help perform statistical analysis or provide customer support.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Security</h2>
        <p className="mb-4">
          We implement security measures to maintain the safety of your personal information when you enter, submit, or access it.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Cookies</h2>
        <p className="mb-4">
          We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Your Rights</h2>
        <p className="mb-4">You have the right to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Access your personal data</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Withdraw consent</li>
        </ul>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Changes to This Policy</h2>
        <p className="mb-4">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
        </p>

        <p className="mt-8 text-sm text-gray-600">
          If you have any questions about this Privacy Policy, please contact us at {siteConfig.social.email}
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;