import React from 'react';
import { Link } from 'react-router-dom';

export const navItems = [
  { name: 'Home', href: '/' },
  { name: 'Blog', href: '/blog' },
  { name: 'Featured Products', href: '/featured-products' },
  { name: 'Who Can Benefit?', href: '/infographic' },
  { name: 'Safety & Maintenance', href: '/safety-maintenance', children: [
    { name: 'Bathroom Safety Guide', href: '/bathroom-safety' },
    { name: 'Maintenance Checklist', href: '/downloads/maintenance-checklist.html' },
    { name: 'Safety Tips', href: '/infographic' },
  ]},
  { name: 'Measurement Guide', href: '/measurement-guide' },
  { name: 'Resources', href: '#', children: [
    { name: 'Medical Disclaimer', href: '/medical-disclaimer' },
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Affiliate Disclosure', href: '/affiliate-disclosure' }
  ]}
] as const;

export interface NavItemProps {
  item: typeof navItems[number];
}

export function NavItem({ item }: NavItemProps) {
  const hasChildren = 'children' in item;
  
  if (hasChildren) {
    return (
      <div className="relative group">
        <button className="text-gray-600 hover:text-blue-600 px-3 py-2 text-sm font-medium inline-flex items-center">
          {item.name}
          <svg className="ml-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50">
          <div className="py-1" role="menu" aria-orientation="vertical">
            {item.children?.map((child) => (
              <Link
                key={child.name}
                to={child.href}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                {child.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Link
      to={item.href}
      className="text-gray-600 hover:text-blue-600 px-3 py-2 text-sm font-medium"
    >
      {item.name}
    </Link>
  );
}