import React from 'react';
import { FaToilet, FaHandsWash, FaLightbulb, FaWrench } from 'react-icons/fa';
import { GiShower, GiStairs } from 'react-icons/gi';
import { MdAccessible, MdSecurity } from 'react-icons/md';
import { BiSupport } from 'react-icons/bi';

const BathroomSafetyInfographic: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-12 bg-gray-50">
      {/* Title Section */}
      <div className="text-center mb-16">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Transform Your Bathroom for Maximum Safety
        </h1>
        <p className="text-xl text-gray-600">
          A comprehensive guide to creating a safer bathroom environment
        </p>
      </div>

      {/* Main Infographic Container */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Side - Bathroom Illustration */}
        <div className="relative bg-white p-8 rounded-lg shadow-lg min-h-[600px]">
          <div className="relative h-full bg-blue-50 rounded-lg p-4">
            {/* Bathroom outline */}
            <div className="absolute inset-0 border-4 border-blue-100 rounded-lg opacity-50"></div>
            
            {/* Step 2: Raised Toilet Seat */}
            <div className="absolute left-8 bottom-32">
              <div className="bg-blue-100 rounded-full p-4 shadow-md">
                <FaToilet className="w-8 h-8 text-blue-600" />
              </div>
              <div className="absolute left-16 top-1/2 -translate-y-1/2 bg-white p-3 rounded-lg shadow-md w-48 z-10">
                <p className="text-sm font-semibold">Raised Toilet Seat</p>
                <p className="text-xs text-gray-600">2-6 inches height increase</p>
              </div>
            </div>

            {/* Step 3: Grab Bars */}
            <div className="absolute right-8 top-32">
              <div className="bg-blue-100 rounded-full p-4 shadow-md">
                <BiSupport className="w-8 h-8 text-blue-600" />
              </div>
              <div className="absolute right-16 top-1/2 -translate-y-1/2 bg-white p-3 rounded-lg shadow-md w-48 z-10">
                <p className="text-sm font-semibold">Grab Bars</p>
                <p className="text-xs text-gray-600">Reduces fall risk by 40%</p>
              </div>
            </div>

            {/* Step 4: Non-Slip Mats */}
            <div className="absolute right-32 bottom-16">
              <div className="bg-blue-100 rounded-full p-4 shadow-md">
                <MdSecurity className="w-8 h-8 text-blue-600" />
              </div>
              <div className="absolute right-16 top-1/2 -translate-y-1/2 bg-white p-3 rounded-lg shadow-md w-48 z-10">
                <p className="text-sm font-semibold">Non-Slip Mats</p>
                <p className="text-xs text-gray-600">Prevents slipping on wet surfaces</p>
              </div>
            </div>

            {/* Step 5: Shower Safety */}
            <div className="absolute left-32 top-16">
              <div className="bg-blue-100 rounded-full p-4 shadow-md">
                <GiShower className="w-8 h-8 text-blue-600" />
              </div>
              <div className="absolute left-16 top-1/2 -translate-y-1/2 bg-white p-3 rounded-lg shadow-md w-48 z-10">
                <p className="text-sm font-semibold">Shower Chair & Handheld Head</p>
                <p className="text-xs text-gray-600">Enhanced bathing safety</p>
              </div>
            </div>

            {/* Center bathroom icon */}
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="bg-blue-200 rounded-full p-6 shadow-lg">
                <FaHandsWash className="w-12 h-12 text-blue-700" />
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Detailed Information */}
        <div className="space-y-8">
          {/* Safety Features List */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Essential Safety Features</h2>
            
            <div className="space-y-4">
              {/* Raised Toilet Seat */}
              <div className="flex items-start space-x-4">
                <FaToilet className="w-6 h-6 text-blue-600 mt-1" />
                <div>
                  <h3 className="font-semibold">Raised Toilet Seat</h3>
                  <p className="text-sm text-gray-600">
                    Adds 2-6 inches in height, reducing strain on hips and knees.
                    Over 70% of occupational therapists recommend for mobility issues.
                  </p>
                </div>
              </div>

              {/* Grab Bars */}
              <div className="flex items-start space-x-4">
                <BiSupport className="w-6 h-6 text-blue-600 mt-1" />
                <div>
                  <h3 className="font-semibold">Grab Bars</h3>
                  <p className="text-sm text-gray-600">
                    Secure grab bars reduce fall risk by up to 40%.
                    Install next to toilet and in shower for maximum support.
                  </p>
                </div>
              </div>

              {/* Non-Slip Mats */}
              <div className="flex items-start space-x-4">
                <MdSecurity className="w-6 h-6 text-blue-600 mt-1" />
                <div>
                  <h3 className="font-semibold">Non-Slip Mats</h3>
                  <p className="text-sm text-gray-600">
                    Essential for wet areas. Choose mats with strong suction grips
                    and quick-drying materials.
                  </p>
                </div>
              </div>

              {/* Shower Safety */}
              <div className="flex items-start space-x-4">
                <GiShower className="w-6 h-6 text-blue-600 mt-1" />
                <div>
                  <h3 className="font-semibold">Shower Enhancements</h3>
                  <p className="text-sm text-gray-600">
                    Shower chair provides stability while bathing.
                    Handheld showerhead allows seated bathing.
                  </p>
                </div>
              </div>

              {/* Lighting */}
              <div className="flex items-start space-x-4">
                <FaLightbulb className="w-6 h-6 text-blue-600 mt-1" />
                <div>
                  <h3 className="font-semibold">Improved Lighting</h3>
                  <p className="text-sm text-gray-600">
                    Bright, glare-free lighting reduces fall risk.
                    Consider motion sensors for nighttime use.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Maintenance Tips */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Maintenance Tips</h2>
            <div className="flex items-start space-x-4">
              <FaWrench className="w-6 h-6 text-blue-600 mt-1" />
              <div>
                <ul className="list-disc list-inside text-sm text-gray-600 space-y-2">
                  <li>Check grab bar stability monthly</li>
                  <li>Replace worn-out non-slip mats</li>
                  <li>Keep all surfaces clean and dry</li>
                  <li>Test motion sensors and lights regularly</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Before and After Comparison */}
      <div className="mt-16 bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">Before & After Impact</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Before */}
          <div className="text-center">
            <div className="bg-gray-100 rounded-lg p-6 mb-4">
              <MdAccessible className="w-16 h-16 text-gray-400 mx-auto" />
              <h3 className="font-semibold mt-4">Standard Bathroom</h3>
              <p className="text-sm text-gray-600">Limited accessibility and safety features</p>
            </div>
          </div>

          {/* After */}
          <div className="text-center">
            <div className="bg-blue-50 rounded-lg p-6 mb-4">
              <MdAccessible className="w-16 h-16 text-blue-600 mx-auto" />
              <h3 className="font-semibold mt-4">Safety-Enhanced Bathroom</h3>
              <p className="text-sm text-gray-600">50% reduction in fall incidents</p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div className="mt-16 text-center">
        <h2 className="text-2xl font-bold mb-4">Ready to Transform Your Bathroom?</h2>
        <p className="text-gray-600 mb-8">
          Explore our recommended products to create your safer bathroom environment
        </p>
        <a
          href="/featured-products"
          className="inline-block bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition-colors"
        >
          View Our Products
        </a>
      </div>
    </div>
  );
};

export default BathroomSafetyInfographic;
