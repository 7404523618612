import React from 'react';
import { siteConfig } from '../constants/siteConfig';

const AffiliateDisclosure: React.FC = () => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8">Affiliate Disclosure</h1>
      
      <div className="prose prose-blue max-w-none">
        <p className="mb-4">Last updated: {new Date().toLocaleDateString()}</p>
        
        <h2 className="text-2xl font-semibold mt-8 mb-4">FTC Disclosure Compliance</h2>
        <p className="mb-4">
          In accordance with the FTC guidelines, this website, {siteConfig.name}, declares that it participates in various affiliate marketing programs. These programs allow us to earn commissions through advertising and linking to affiliate partner websites, including but not limited to Amazon.com.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Amazon Associate Disclosure</h2>
        <p className="mb-4">
          As an Amazon Associate, we earn from qualifying purchases. This means that when you click on links to various products from our site and make a purchase, this can result in us earning a commission.
        </p>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Our Commitment to You</h2>
        <p className="mb-4">
          We promise to:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Only recommend products we believe will benefit our readers</li>
          <li>Be transparent about our affiliate relationships</li>
          <li>Provide honest and unbiased reviews</li>
          <li>Clearly mark affiliate links when possible</li>
        </ul>

        <p className="mt-8 text-sm text-gray-600">
          If you have any questions about this disclosure, please contact us at {siteConfig.social.email}
        </p>
      </div>
    </div>
  );
}

export default AffiliateDisclosure;