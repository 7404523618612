import React from 'react';
import { Accessibility } from 'lucide-react';
import { siteConfig } from '../../constants/siteConfig';

export function Logo() {
  return (
    <div className="flex items-center">
      <Accessibility className="h-8 w-8 text-blue-600" />
      <span className="ml-2 text-xl font-semibold text-gray-900">{siteConfig.name}</span>
    </div>
  );
}